import React, { useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SearchModule from "../components/search2/searchModule"
import SEO from "../components/seo"
import Breadcrumb from "../components/layout/breadcrumb"
import Collapsible from "react-collapsible"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"

const ManufacturerDetail = ({ data, location }) => {
  const [searchFilters, setSearchFilters] = useState({
    refinementList: { manufacturerName: [data.manufacturer.name] },
    page: 1,
  })
  const [manufacturerName, setManufacturerName] = useState(
    data.manufacturer.name
  )
  const [expanderOpen, setExpanderOpen] = useState(false)

  return (
    <Layout>
      <SEO
        title={`${manufacturerName} Lab Equipment for Sale`}
        description={manufacturerName}
        keywords={manufacturerName}
        canonicalUrl={location.origin + location.pathname}
      />
      <Breadcrumb
        paths={[
          { name: "Manufacturers", path: "buy-equipment/manufacturers" },
          { name: manufacturerName, path: location.pathname.slice(1) },
        ]}
      />

      <div className="container max-w-screen-xl pt-10 pb-14">
        <Link
          to="/buy-equipment/manufacturers"
          className="text-[1.5rem] font-semibold text-medium-gray pb-3"
        >
          Manufacturers
        </Link>
        <h1 className="text-balance my-0 pb-5 text-red">{manufacturerName}</h1>

        <div>
          <Collapsible
            trigger={
              <p
                dangerouslySetInnerHTML={{
                  __html: data.manufacturer.description,
                }}
              />
            }
            classParentString="custom"
            triggerDisabled
            open={expanderOpen}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: data.manufacturer.overflowDescription,
              }}
            />
          </Collapsible>
          {data.manufacturer.overflowDescription && (
            <div style={{ textAlign: "right" }} className="mt-2">
              {expanderOpen ? (
                <a
                  className="text-[0.875rem] text-charcoal"
                  onClick={() => setExpanderOpen(!expanderOpen)}
                >
                  <FontAwesomeIcon icon={faAngleUp} /> Show Less
                </a>
              ) : (
                <a
                  className="text-[0.875rem] text-charcoal"
                  onClick={() => setExpanderOpen(!expanderOpen)}
                >
                  <FontAwesomeIcon icon={faAngleDown} /> Show More
                </a>
              )}
            </div>
          )}
        </div>
      </div>
      <SearchModule showManufacturer={false} searchFilters={searchFilters} />
    </Layout>
  )
}

export default ManufacturerDetail

export const query = graphql`
  query ($slug: String!) {
    manufacturer(slug: { eq: $slug }) {
      name
      description
      overflowDescription
    }
  }
`
